@charset "UTF-8";
/* ------------------------------------------------------------------------
  Reset
  html5doctor.com Reset Stylesheet
  v1.6.1
  Last Updated: 2010-09-17
  Author: Richard Clark - http://richclarkdesign.com
  Twitter: @rich_clark
--------------------------------------------------------------------------*/
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

body {
  line-height: 1;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

input, select {
  vertical-align: middle;
}

/* ------------------------------------------------------------------------
  Base
--------------------------------------------------------------------------*/
html {
  font-size: 62.5%;
}

body, button {
  font-size: 1.6em;
  -webkit-text-size-adjust: 100%;
  font-family: -apple-system,BlinkMacSystemFont,"Helvetica Neue","Hiragino Kaku Gothic ProN","游ゴシック体",YuGothic,"游ゴシック Medium","Yu Gothic Medium","游ゴシック","Yu Gothic","メイリオ",Meiryo,sans-serif;
}

@media screen and (max-width: 767px) {
  body {
    font-size: 1.3em;
  }
}

/****
**
** フォームのリセット
**
****/
input, button, textarea, select {
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/****
**
** テキストリンク
**
****/
a {
  color: #000;
  text-decoration: none;
  transition: color 0.1s ease;
}

a:hover {
  color: #4685da;
  text-decoration: underline;
}

/* 処理中くるくる */
.overlay_cover_on_executing_wrap {
  position: relative;
}
.overlay_cover_on_executing {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  width: auto;
  height: auto;
  justify-content: center;
  background-color: rgba(255,255,255,0.5);
  align-items: center;
}
.overlay_cover_on_executing img {
  display: block;
  height: 50%;
}

/* パンくずリスト */
.breadcrumbs {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
}

.breadcrumbs li {
  margin-bottom: 0.6em;
}


.breadcrumbs li:not(:last-of-type)::after {
  content: "›";
  margin: 0 .6em;
  color: #000;
}

.breadcrumb {
  color: -webkit-link;
  text-decoration: underline;
}

.last_breadcrumb {
  pointer-events: none;
}
