/* ------------------------------------------------------------------------

  common

--------------------------------------------------------------------------*/
/* ------------------------------------------------------------------------
  module
--------------------------------------------------------------------------*/
/****
**
** > 1024 box
**
****/
.wide-s {
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (min-width: 1025px) {
  .wide-s {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

/****
**
** path
**
****/
.path {
  text-decoration: underline;
  letter-spacing: 0.1em;
}


/* ------------------------------------------------------------------------
  button
--------------------------------------------------------------------------*/
.button {
  padding: 1.221vw 0.977vw 1.123vw 1.074vw;
  background: white;
  background: linear-gradient(180deg, white 0%, #f0f0f0 100%);
  font-size: 1.953vw;
  cursor: pointer;
  border: 1px solid #b3b3b3 !important;
  border-radius: 5px;
  box-shadow: 2px 2px 3px 1px rgba(0, 0, 0, 0.3);
  text-decoration: none;
  color: #000000;
  transition: opacity .2s ease;
}

.button:hover {
  opacity: 0.9;
}

.header .button:hover {
  opacity: 0.7;
}
.overlay_cover_on_executing img {
  max-width: 50px;
  height: auto;
}
.loading{
  position: static !important;
}
.loading:hover {
  opacity: 1;
}

.main .button {
  font-size: 1.563vw;
}

.button.button-blue {
  border-color: #304f96;
  background: #677eb2;
  background: linear-gradient(180deg, #677eb2 30%, #4460a0 100%);
  color: #fff;
}

.button.button-blue:hover {
  opacity: 0.7;
}

.button.button-blueBright {
  border-color: #0058c3;
  background: #4686db;
  background: linear-gradient(180deg, #4686db 0%, #226ed4 100%);
  color: #fff;
}

.button.button-blueBright:hover {
  opacity: 0.7;
}

.button.button-prev {
  padding: 0;
  background: none;
  border: none !important;
  box-shadow: none;
}

.button.button-prev span {
  display: block;
  margin-left: 14px;
  padding: 1.221vw 1.7vw 1.123vw 1.074vw;
  background: url(/public/common/image/parts/button_prev_2.png) repeat center center;
  background-size: auto 100%;
  position: relative;
}

.button.button-prev span::before, .button.button-prev span::after {
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
}

.button.button-prev span::before {
  min-width: 25px;
  background: url(/public/common/image/parts/button_prev_1.png) no-repeat center center;
  background-size: auto 100%;
  left: -14px;
}

.button.button-prev span::after {
  min-width: 14px;
  background: url(/public/common/image/parts/button_prev_3.png) no-repeat center center;
  background-size: auto 100%;
  right: -2px;
}

.button.button-next {
  padding: 0;
  background: none;
  border: none;
  box-shadow: none;
}

.button.button-next span {
  display: block;
  margin-right: 14px;
  padding: 1.221vw 1.7vw 1.123vw 1.7vw;
  background: url(/public/common/image/parts/button_prev_2.png) repeat center center;
  background-size: auto 100%;
  position: relative;
}

.button.button-next span::before, .button.button-next span::after {
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
}

.button.button-next span::before {
  min-width: 26px;
  background: url(/public/common/image/parts/button_prev_1.png) no-repeat center center;
  background-size: auto 100%;
  right: -15px;
  -webkit-transform: scale(-1, 1);
          transform: scale(-1, 1);
}

.button.button-next span::after {
  min-width: 25px;
  background: url(/public/common/image/parts/button_prev_3.png) no-repeat center center;
  background-size: auto 100%;
  left: -8px;
  -webkit-transform: scale(-1, 1);
          transform: scale(-1, 1);
}

.button.button-search span {
  padding-left: 3.223vw;
  position: relative;
}

.button.button-search span::before {
  content: "";
  display: block;
  width: 2.441vw;
  height: 2.441vw;
  background: url(/public/common/image/icon/icon_search.png) no-repeat center center;
  background-size: 100%;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.button.button-newfolder {
  padding: 16px 20px 16px 62px;
  border-color: #0058c3;
  background: #4686db;
  background: linear-gradient(180deg, #4686db 0%, #226ed4 100%);
  color: #ffffff;
  font-size: 1.953vw;
  font-weight: bold;
  position: relative;
}

.button.button-newfolder::before {
  content: "";
  display: block;
  width: 37px;
  height: 28px;
  background: url(/public/common/image/icon/icon_folder_plus.png) no-repeat center center;
  background-size: 100%;
  position: absolute;
  left: 31px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  top: 0;
  bottom: 0;
  margin: auto;
}

.button.button-newfolder:hover {
  opacity: 0.7;
}

.button.button-move {
  width: 50px;
  height: 50px;
  padding: 0;
  background: url(/public/common/image/icon/icon_move.png) no-repeat center center;
  background-size: 13px auto;
}

.button.button-strong {
  font-weight: bold;
  border-color: #808080;
}

.button.button-storage {
  background: #50a17b;
  color: #ffffff;
}

.button.button-storage:hover {
  opacity: 0.7;
}

.button.button-storage span {
  padding-left: 2.49vw;
  position: relative;
}

.button.button-storage span::before {
  content: "";
  display: block;
  width: 1.709vw;
  height: 1.611vw;
  background: url(/public/common/image/icon/icon_storage.png) no-repeat center center;
  background-size: 100%;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.button.button-delete {
  background: #4d4d4d;
  color: #ffffff;
}

.button.button-delete:hover {
  opacity: 0.7;
}

.button.button-delete span {
  padding-left: 2.051vw;
  position: relative;
}

.button.button-delete span::before {
  content: "";
  display: block;
  width: 1.367vw;
  height: 1.758vw;
  background: url(/public/common/image/icon/icon_dust.png) no-repeat center center;
  background-size: 100%;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.button.button-close {
  width: 10vw;
  height: 10vw;
  padding: 0;
  background: url(/public/common/image/icon/icon_close.png) no-repeat center;
  background-size: 5vw;
  border-width: 2px;
  box-sizing: border-box;
}

@media screen and (min-width: 1025px) {
  .button {
    padding: 14px 25px;
    font-size: 18px;
  }
  .main .button {
    font-size: 18px;
  }
  .button.button-prev span {
    margin-left: 21px;
  }
  .button.button-prev span::before {
    min-width: 30px;
    left: -21px;
  }
  .button.button-prev span::after {
    min-width: 19px;
  }
  .button.button-newfolder {
    font-size: 18px;
  }
  .button.button-delete span::before {
    width: 15px;
    height: 19px;
  }
  .button.button-close {
    width: 5.615vw;
    height: 5.615vw;
    background-size: 1.782vw;
  }
}

.select {
  position: relative;
}

.select:after{
  display: block;
  content: " ";
  position: absolute;
  top: 50%;
  right: 20px;
  width: 15px;
  height: 11px;
  margin-top: -6px;
  background: url(/public/common/image/icon/icon_arrow.png) 0 0 no-repeat;
  background-size: 100% auto;
  pointer-events: none;
}

.select select{
  border: 2px solid #b3b3b3;
  border-radius: 5px;
  padding: 10px 50px 10px 20px;
  font-size: 18px;
}
.select select::-ms-expand {
  display: none;/* IE */
}

input[type=file] {
  display: none;
}


/* ------------------------------------------------------------------------
  form
--------------------------------------------------------------------------*/
input[type="checkbox"] {
  display: none;
}

input[type="checkbox"] + label {
  padding-left: calc(2.93vw + 16px);
  position: relative;
}

input[type="checkbox"] + label::before, input[type="checkbox"] + label::after {
  content: "";
  display: block;
  width: 2.93vw;
  height: 2.93vw;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

input[type="checkbox"] + label::before {
  background: white;
  background: linear-gradient(180deg, white 0%, #cdcdcd 100%);
  border: 2px solid #4c4c4c;
  border-radius: 5px;
}

input[type="checkbox"] + label::after {
  background: url(/public/common/image/icon/icon_check.png) no-repeat center;
  background-size: 70% auto;
  -webkit-transform: translateX(15%);
          transform: translateX(15%);
  opacity: 0;
}

input[type="checkbox"] + label:empty {
  width: 0;
  height: 0;
  padding-left: calc(2.93vw + 16px);
}

input[type="checkbox"] + label:empty::before, input[type="checkbox"] + label:empty::after {
  right: 0;
}

input[type="checkbox"] + label:empty::after {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

input[type="checkbox"]:checked + label::before {
  background: linear-gradient(180deg, black 0%, #8a8a8a 100%) center center;
  background-repeat: no-repeat;
  background-size: 100% auto;
}

input[type="checkbox"]:checked + label::after {
  opacity: 1;
}

input[type="radio"] {
  display: none;
}

input[type="radio"] + label {
  position: relative;
  display: inline-block;
  padding: 3px 3px 3px 4vw;
  font-size: 1.758vw;
}

input[type="radio"] + label::before, input[type="radio"] + label::after {
  position: absolute;
  content: '';
  top: 50%;
  border-radius: 100%;
}

input[type="radio"] + label::before {
  left: 0;
  width: 2.5vw;
  height: 2.4vw;
  background-color: #f7f7f7;
  border: 2px solid #4d4d4d;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

input[type="radio"] + label::after {
  opacity: 0;
  width: 1.4vw;
  height: 1.4vw;
  background: #4d4d4d;
  position: absolute;
  left: calc(1.25vw + 2px);
  top: 0;
  bottom: 0;
  margin: auto;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

input[type="radio"] + label + input[type="radio"] + label {
  margin-left: 3.052vw;
}

input[type="radio"]:checked + label::before {
  border: 2px solid #4d4d4d;
}

input[type="radio"]:checked + label::after {
  opacity: 1;
}

input[type="text"] {
  border: 2px solid #808080;
  padding: 1.709vw;
  box-sizing: border-box;
  font-size: 1.758vw;
  background-color: #ffffff;
}

input[type="text"]::-webkit-input-placeholder {
  color: #999999;
}

input[type="text"]::-moz-placeholder {
  color: #999999;
}

input[type="text"]:-ms-input-placeholder {
  color: #999999;
}

input[type="password"] {
  border: 2px solid #808080;
  padding: 1.709vw;
  box-sizing: border-box;
  font-size: 1.758vw;
  background-color: #ffffff;
}

@media screen and (min-width: 1025px) {
  input[type="checkbox"] + label {
    padding-left: 46px;
    position: relative;
  }
  input[type="checkbox"] + label::before, input[type="checkbox"] + label::after {
    width: 30px;
    height: 30px;
  }
  input[type="checkbox"] + label:empty {
    padding-left: calc(46px + 16px);
  }
  input[type="radio"] {
    display: none;
  }
  input[type="radio"] + label {
    font-size: 18px;
    padding: 3px 3px 3px 48px;
  }
  input[type="radio"] + label::before {
    width: 30px;
    height: 30px;
  }
  input[type="radio"] + label::after {
    width: 20px;
    height: 20px;
    left: 17px;
  }
  input[type="text"],
  input[type="password"] {
    padding: 16px 20px;
    box-sizing: border-box;
    font-size: 16px;
  }
}


/* ------------------------------------------------------------------------
  icon
--------------------------------------------------------------------------*/
.icon-folder {
  display: block;
  padding-left: 50px;
  position: relative;
}
div.table > table > tbody > tr> td:nth-child(2) {
  position: relative;
}
.pass_box {
  display: none;
  /* display: block; */
  width: auto;
  max-width: 90%;
  position: absolute;
  word-wrap: break-word;
  bottom: 85px;
  padding: 16px;
  border-radius: 5px;
  background: #999999;
  color: #fff;
}
.pass_box:after {
  position: absolute;
  width: 0;
  height: 0;
  left: 0;
  bottom: -19px;
  margin-left: 10px;
  border: solid transparent;
  border-color: rgba(51, 204, 153, 0);
  border-top-color: #999999;
  border-width: 10px;
  pointer-events: none;
  content: " ";
}
.icon-folder:hover + .pass_box , .icon-file:hover + .pass_box {
  display: block;
}

.icon-folder::before {
  content: "";
  display: block;
  width: 39px;
  height: 28px;
  background: url(/public/common/image/icon/icon_folder.png) no-repeat center center;
  background-size: 100% auto;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.icon-folder.icon-white::before {
  background-image: url(/public/common/image/icon/icon_folder_w.png);
}

.icon-file {
  display: block;
  padding-left: 50px;
  position: relative;
}

.icon-file::before {
  content: "";
  display: block;
  width: 25px;
  height: 32px;
  background: url(/public/common/image/icon/icon_file.png) no-repeat center center;
  background-size: 100% auto;
  position: absolute;
  left: 6px;
  top: 0;
  bottom: 0;
  margin: auto;
}


/* ------------------------------------------------------------------------
  arrow
--------------------------------------------------------------------------*/
.arrow {
  width: 32px;
  height: 32px;
  border-left: 8px solid #ffffff;
  border-bottom: 8px solid #ffffff;
  box-sizing: border-box;
  position: relative;
}

.arrow::after {
  content: '';
  display: block;
  width: 26px;
  height: 26px;
  border-left: 4px solid #9b9b9b;
  border-bottom: 4px solid #9b9b9b;
  box-sizing: border-box;
  position: absolute;
  left: -6px;
  top: 4px;

}

.arrow.arrow-prev {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.arrow.arrow-next {
  -webkit-transform: rotate(-135deg);
          transform: rotate(-135deg);
}

/* ------------------------------------------------------------------------
  basic common
--------------------------------------------------------------------------*/
/****
**
** header
**
****/
.header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 22px;
  background-color: #4460a1;
  color: #ffffff;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.4);
}

.header .pagename {
  margin-left: 2.344vw;
  font-size: 2.344vw;
  flex-grow: 2;
  font-weight: bold;
  letter-spacing: 0.04em;
  width: min-content;
  word-break: break-all;
}

@media all and (-ms-high-contrast: none){
  .header .pagename {
    width: 580px;
    word-break: break-all;
  }
  .icon-file, .icon-folder {
    padding-top: 11px;
    word-break: break-all;
  }
  div.actionfield_action select > * {
    max-width: 100% !important;
  }
  .button.button-delete {
    overflow: visible;
  }
  .button-prev span{
    padding-top: 21px !important;
  }
  .button {
    padding-top: 22px;
    padding-bottom: 18px;
  }
  .button.button-blueBright.drag {
    padding-top: 20px !important;
  }
}

.header .pagename:first-child {
  margin: 0;
}

@media screen and (min-width: 1025px) {
  .header .pagename {
    font-size: 24px;
  }
}

/****
**
** footer
**
****/
.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.221vw 2.344vw 1.221vw 1.221vw;
  background-color: #ffffff;
}

.footer .copyright {
  font-size: 0.977vw;
  color: #b3b3b3;
}

/****
**
** main
**
****/
.main {
  margin: 0 auto;
  padding: 3.906vw 2.344vw;
  background-color: #f1f3f8;
  display: block;
}

/****
**
** group
**
****/
.group .group_cell {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

/****
**
** link
**
****/
.link {
  font-size: 1.758vw;
  color: #000;
}

@media screen and (min-width: 1025px) {
  .link {
    font-size: 18px;
  }
}

.copy-link-display {
  display: inline-block;
}

.copy-link-storage {
  position: absolute; 
  right: 0; 
  margin: 0px 24px;
}

.copy-link {
  text-decoration: underline;
  font-size: 15px;
  color: #000000;
  opacity: 1;
  cursor: pointer;
}

/* ------------------------------------------------------------------------
  modal
--------------------------------------------------------------------------*/
@-webkit-keyframes fadein {
  0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: block;
    opacity: 0;
  }
  100% {
    display: block;
    opacity: 1;
  }
}
@keyframes fadein {
  0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: block;
    opacity: 0;
  }
  100% {
    display: block;
    opacity: 1;
  }
}

.modal {
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 0 5.005vw;
  box-sizing: border-box;
  z-index: 9999;
  opacity: 0;
}

.modal.is-show {
  display: block;
  opacity: 1;
  -webkit-animation-name: fadein;
          animation-name: fadein;
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
}

.modal_content {
  width: calc(100% - 10.01vw);
  max-height: 80vh;
  padding: 5.127vw 5.127vw 7.813vw;
  background-color: #ffffff;
  box-shadow: 0px 0px 25px 10px rgba(110, 110, 110, 0.2);
  position: absolute;
  top: 10vh;
  left: 0;
  right: 0;
  margin: auto;
  box-sizing: border-box;
  overflow: scroll;
  -ms-overflow-style: none;
}

.modal_content::-webkit-scrollbar {
  display: none;
}

.modal_content::-webkit-scrollbar-track {
  display: none;
}

.modal_content::-webkit-scrollbar-thumb {
  display: none;
}

.modal_content .title {
  font-size: 2.441vw;
}

.modal_content .explanation {
  margin-bottom: 5.469vw;
  font-size: 1.758vw;
}

.modal_content .inputfield {
  margin-top: 7.08vw;
  padding: 0 9.808vw;
}

.modal_content .inputfield label {
  font-size: 1.758vw;
}

.modal_content .inputfield input[type="text"] {
  display: block;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 20px;
}

.modal_content .inputfield .inputfield_unit {
  margin-top: 3.906vw;
  display: flex;
  justify-content: center;
}

.modal_content .inputfield .inputfield_unit .button {
  width: 18.625vw;
  padding: 1.367vw 0;
}

.modal_content .inputfield .inputfield_unit .button + .button {
  margin-left: 2.344vw;
}

.modal_content .actionfield {
  margin-top: 4.688vw;
}

.modal_content .actionfield .explanation {
  padding-top: 2.344vw;
  margin-bottom: 4.688vw;
}

.modal_content .actionfield .actionfield_action,
.modal_content .actionfield .actionfield_confirm {
  display: flex;
  align-items: center;
  padding: 2.344vw 0;
  font-size: 1.758vw;
}

.modal_content .actionfield .actionfield_action .actionfield_head,
.modal_content .actionfield .actionfield_confirm .actionfield_head {
  width: 33%;
}

.modal_content .actionfield .actionfield_action .actionfield_body,
.modal_content .actionfield .actionfield_confirm .actionfield_body {
  width: 47%;
}

.modal_content .actionfield .actionfield_action .actionfield_execution,
.modal_content .actionfield .actionfield_confirm .actionfield_execution {
  width: 33%;
}

.modal_content .actionfield .actionfield_action .actionfield_execution .button,
.modal_content .actionfield .actionfield_confirm .actionfield_execution .button {
  width: 18.625vw;
}

.modal_content .actionfield .actionfield_action .actionfield_execution .button small,
.modal_content .actionfield .actionfield_confirm .actionfield_execution .button small {
  font-size: 75%;
}

.modal_content .actionfield .actionfield_action {
  border-bottom: 3px solid #cccccc;
}

.modal_content .actionfield .actionfield_confirm {
  border-bottom: 3px solid #cccccc;
}

.modal_content .actionfield .actionfield_confirm .actionfield_row {
  display: flex;
  align-items: center;
  height: 32px;
}

.modal_content .actionfield .actionfield_confirm .actionfield_row + .actionfield_row {
  margin-top: 16px;
}

.modal_content .actionfield .actionfield_unit {
  margin-top: 3.906vw;
  display: flex;
  justify-content: center;
}

.modal_content .actionfield .button {
  width: 18.625vw;
  padding: 1.367vw 0;
}

.modal_content .actionfield .button + .button {
  margin-left: 2.344vw;
}

.modal_content .messagefield {
  margin-top: 4.688vw;
}

.modal_content .messagefield .message_content {
  text-align: center;
  line-height: 1.3;
}

.modal_content .messagefield .messagefield_unit {
  margin-top: 3.906vw;
  display: flex;
  justify-content: center;
}

.modal_content .messagefield .button {
  width: 18.625vw;
  padding: 1.367vw 0;
}

.modal_content .messagefield .button + .button {
  margin-left: 2.344vw;
}


.modal_content .button-close {
  position: absolute;
  top: 1.904vw;
  right: 1.904vw;
}

.modal .modal-search {
  padding: 7.16vw 14.935vw;
}

.modal .modal-search .radio {
  margin-top: 3.418vw;
}

.modal .modal-search .search {
  display: flex;
  margin-top: 1.587vw;
}

.modal .modal-search .search input[type="text"] {
  width: calc(100% - 11.719vw - 0.977vw);
}

.modal .modal-search .search span {
  width: 11.719vw;
  margin-left: 0.977vw;
  position: relative;
}

.modal .modal-search .search span .icon-search {
  width: 2.466vw;
  height: 2.417vw;
  background: url(/public/common/image/icon/icon_search.png) no-repeat center;
  background-size: 2.466vw auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  -webkit-transform: translateX(-2.7vw);
          transform: translateX(-2.7vw);
}

.modal .modal-search .search span .button.button-search {
  width: 100%;
  height: 100%;
  padding-left: 3.223vw;
}

.modal .modal-viewer {
  background: none;
  padding: 0;
  box-shadow: none;
  width: calc(100% - 4.01vw);
  top: 5vh;
  max-height: 90vh;
  overflow: hidden;
}

.modal .modal-viewer .viewer {
  display: flex;
  margin-top: 16px;
  max-height: 80vh;
}

.modal .modal-viewer .viewer .viewer_main {
  width: 100%;
  max-height: calc(90vh - 2.4em - 16px);
  overflow: auto;
}

.modal .modal-viewer .viewer .viewer_main span {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 600px;
  border: 2px solid #cccccc;
  background-color: #f1f1f1;
  color: #808080;
}

.modal .modal-viewer .viewer .arrow {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}

.modal .modal-viewer .viewer .arrow.arrow-prev {
  left: 2.344vw;
}

.modal .modal-viewer .viewer .arrow.arrow-next {
  right: 2.344vw;
}

.modal .modal-viewer .button-close {
  box-shadow: none;
  position: fixed;
  border: none;
  top: 0;
  right: 0;
}

@media screen and (min-width: 1025px) {
  .modal .title {
    font-size: 24px;
  }
  .modal .explanation {
    margin-bottom: 56px;
    font-size: 18px;
  }
  .modal .inputfield {
    margin-top: 72px;
  }
  .modal .inputfield label {
    font-size: 18px;
  }
  .modal .inputfield .inputfield_unit {
    margin-top: 40px;
  }
  .modal .inputfield .inputfield_unit.inputfield_unit-2 .button {
    width: 210px;
    padding: 14px 0;
  }
  .modal .actionfield {
    margin-top: 48px;
  }
  .modal .actionfield .explanation {
    padding-top: 24px;
    margin-bottom: 48px;
  }
  .modal .actionfield .actionfield_execution .button {
    width: 210px !important;
  }
  .modal .actionfield .actionfield_action,
  .modal .actionfield .actionfield_confirm {
    padding: 24px 0;
    font-size: 18px;
  }
  .modal .actionfield .actionfield_action .button {
    padding: 14px 0;
  }
  .modal .actionfield .actionfield_unit {
    margin-top: 40px;
  }
  .modal .actionfield .actionfield_unit .button {
    width: 210px;
    padding: 14px 0;
  }
}


/* ------------------------------------------------------------------------
  Login
--------------------------------------------------------------------------*/
.login .header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  box-sizing: border-box;
}

.login .footer {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10;
  box-sizing: border-box;
}

.login .main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
}

.login .group {
  width: 62.012vw;
}

.login .group .group_cell {
  justify-content: flex-end;
}

.login .group .group_cell + .group_cell {
  margin-top: 3.418vw;
}

.login .group .group_cell label {
  width: calc(100% - 49.805vw);
  font-size: 1.953vw;
}

.login .group .group_cell input {
  width: 49.805vw;
}

.login .group .group_cell .link {
  margin-top: 1.709vw;
}

.login .button.button-blue {
  display: block;
  width: 26.633vw;
  box-sizing: border-box;
  margin: 3.906vw auto 0;
}

@media screen and (min-width: 1025px) {
  .login .group {
    max-width: 636px;
  }
  .login .group .group_cell + .group_cell {
    margin-top: 32px;
  }
  .login .group .group_cell label {
    width: 20%;
    font-size: 18px;
  }
  .login .group .group_cell input {
    width: 80%;
  }
  .login .button.button-blue {
    width: 270px;
    font-size: 18px;
    padding: 10px 0;
    margin: 40px auto 0;
  }
}

/* ------------------------------------------------------------------------
  Fire Management
--------------------------------------------------------------------------*/
.intro {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.intro p {
  font-size: 2.441vw;
}

.drag {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 24px 0 20px;
  padding: 20px 0;
  background-color: #ddedff;
  border: dashed 1px #4686db;
  transition: opacity 0.2s ease-out;
}

.prohibited {
  display: flex;
  justify-content: center;
  margin: 24px 0 20px;
  padding: 20px 0;
  width: 100%
}

.drag:hover {
  opacity: 0.5;
}

.drag p {
  display: inline-block;
  padding-left: 16px;
  font-size: 1.953vw;
  font-weight: bold;
  color: #4686db;
  position: relative;
}

.drag p::before, .drag p::after {
  content: "";
  display: block;
  width: 4px;
  height: 14px;
  background-color: #4686db;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.drag p::after {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.drag button {
  margin-left: 20px;
}

.select_menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0 15px;
  padding: 16px 14px;
  font-size: 1.855vw;
  background-color: #f2f2f2;
  border: 1px solid #cccccc;
}

.select_menu .select_all {
  display: block;
  width: 15.695vw;
  min-width: 100px;
  padding: 1.221vw 0 1.123vw;
  box-shadow: 2px 2px 3px 1px rgba(0, 0, 0, 0);
  border: 1px solid transparent;
  line-height: 1;
}

.select_menu .select_list {
  display: flex;
  align-items: center;
}

.select_menu .select_list > span {
  display: none;
  margin-right: 12px;
}

.select_menu .select_list > button {
  display: none;
}

.select_menu .button {
  line-height: 1;
}

.select_menu .button-delete,
.select_menu .button-storage {
  display: none;
}

.select_menu .button.button-delete,
.select_menu .button.button-storage {
  margin-left: 2.94vw;
  position: relative;
  line-height: 1;
}

.select_menu .button.button-delete::before,
.select_menu .button.button-storage::before {
  content: "";
  display: block;
  width: 1px;
  height: 125%;
  background-color: #cccccc;
  position: absolute;
  left: -1.47vw;
  top: -12.5%;
}

.button + .button, #execute_export_csv {
  margin-left: 0.977vw;
}

.table table {
  display: block;
  border-collapse: collapse;
  border-spacing: 0;
}

.table table thead {
  display: table;
  width: 100%;
  border: 1px solid #cccccc;
  background-color: #ffffff;
}

.table table thead tr th {
  padding: 20px 0 15px;
  font-size: 1.855vw;
  font-weight: normal;
  vertical-align: middle;
}

.table table thead tr th + th {
  border-left: 1px solid #cccccc;
}

.table table thead tr th:nth-child(1) {
  width: 6%;
  text-align: center;
}

.table table thead tr th:nth-child(2) {
  width: 53%;
}

.table table thead tr th:nth-child(3) {
  width: 29%;
}

.table table thead tr th:nth-child(4) {
  width: 12%;
  text-align: center;
}

.table table thead tr + tr {
  border-top: 1px solid #cccccc;
}

.table table tbody {
  display: table;
  width: 100%;
  margin-top: 16px;
  border: 1px solid #cccccc;
  background-color: #ffffff;
}

.table table tbody tr:hover {
  background-color: #f7f7f7;
}

.table table tbody tr td {
  padding: 36px 0 28px;
  vertical-align: middle;
  box-sizing: border-box;
  word-break: break-all;
}

.table table tbody tr td + td {
  border-left: 1px solid #cccccc;
}

.table table tbody tr td:nth-child(1) {
  width: 6%;
  text-align: center;
}

.table table tbody tr td:nth-child(2) {
  width: 53%;
  padding-left: 24px;
  padding-right: 24px;
  font-size: 2.344vw;
}

.table table tbody tr td:nth-child(3) {
  width: 29%;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 1.758vw;
}

.table table tbody tr td:nth-child(4) {
  width: 12%;
  text-align: center;
}

.table table tbody tr + tr {
  border-top: 1px solid #cccccc;
}

.table.table-display table tbody td:nth-child(1) {
  padding: 36px 2.344vw;
  text-align: left;
}

.table.table-display table tbody td:nth-child(1) a {
  display: block;
}

.table.table-display table tbody td:nth-child(1) small {
  display: block;
  margin-top: 16px;
  font-size: 1.2vw;
  color: #999;
}

.table .table_aside {
  margin-top: 24px;
}

.table .table_aside.table_aside-right {
  text-align: right;
}

@media screen and (min-width: 1025px) {
  .intro p {
    font-size: 18px;
  }
  .drag p {
    font-size: 18px;
  }
  .select_menu {
    font-size: 16px;
  }
  .select_menu .select_all {
    padding: 20px 0;
    font-size: 18px;
  }
  .table table thead tr th {
    font-size: 18px;
  }
  .table table thead tr th:nth-child(1) {
    width: 61px;
  }
  .table table thead tr th:nth-child(2) {
    width: calc(100% - 50px - 242px - 116px);
  }
  .table table thead tr th:nth-child(3) {
    width: 242px;
  }
  .table table thead tr th:nth-child(4) {
    width: 116px;
  }
  .table table tbody tr td:nth-child(1) {
    width: 61px;
  }
  .table table tbody tr td:nth-child(2) {
    width: calc(100% - 61px - 242px - 116px);
    font-size: 18px;
  }
  .table table tbody tr td:nth-child(3) {
    width: 242px;
    font-size: 16px;
  }
  .table table tbody tr td:nth-child(4) {
    width: 116px;
  }
  .table.table-display table tbody td:nth-child(1) small {
    font-size: 12px;
  }
}

/* ------------------------------------------------------------------------
  switch
--------------------------------------------------------------------------*/
.form-switch {
  display: block;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}
.form-switch .full {
  display: block;
  text-align: center;
  width: 100%;
  padding: 5px 0;
}
.form-switch.right {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  margin: 10px 0 10px auto;
  max-width: 130px;
  position: relative;
  bottom: -30px;
}

.form-switch i {
  position: relative;
  display: inline-block;
  margin-right: .5rem;
  width: 46px;
  height: 26px;
  background-color: #e6e6e6;
  border-radius: 23px;
  vertical-align: text-bottom;
  transition: all 0.3s linear;
}

.form-switch i::before {
  content: "";
  position: absolute;
  left: 0;
  width: 42px;
  height: 22px;
  background-color: #fff;
  border-radius: 11px;
  transform: translate3d(2px, 2px, 0) scale3d(1, 1, 1);
  transition: all 0.25s linear;
}

.form-switch i::after {
  content: "";
  position: absolute;
  left: 0;
  width: 22px;
  height: 22px;
  background-color: #fff;
  border-radius: 11px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24);
  transform: translate3d(2px, 2px, 0);
  transition: all 0.2s ease-in-out;
}

.form-switch:active i::after {
  width: 28px;
  transform: translate3d(2px, 2px, 0);
}

.form-switch:active input:checked + i::after { transform: translate3d(16px, 2px, 0); }

.form-switch input { display: none; }

.form-switch input:checked + i { background-color: #4BD763; }

.form-switch input:checked + i::before { transform: translate3d(18px, 2px, 0) scale3d(0, 0, 0); }

.form-switch input:checked + i::after { transform: translate3d(22px, 2px, 0); }
@media screen and (min-width: 768px) {
  .form-switch.right {
    max-width: 250px;
  }
  .form-switch i {
    width: 80px;
    height: 40px;
    border-radius: 40px;
  }
  .form-switch i::before {
    width: 76px;
    height: 36px;
    border-radius: 36px;
  }
  .form-switch i::after {
    width: 37px;
    height: 35px;
    border-radius: 30px;
  }
.form-switch:active input:checked + i::after { transform: translate3d(40px, 2px, 0); }
.form-switch input:checked + i::before { transform: translate3d(40px, 2px, 0) scale3d(0, 0, 0); }
.form-switch input:checked + i::after { transform: translate3d(40px, 2px, 0); }
}


/* ------------------------------------------------------------------------
  error
--------------------------------------------------------------------------*/
.error_container {
    text-align: center;
    line-height: 1.5;
    color: red;
}
.error_container .error_number {
    margin: 0 0 30px;
}
.error_bg {
  background-color: #f1f3f8 !important;
}
.error_style {
  display: block;
  margin-left: auto;
}
/* storageview.html end */



/* ------------------------------------------------------------------------
  swiper
--------------------------------------------------------------------------*/
.swiper-paging{
    position: relative;
    width: 100%;
    text-align: center;
    margin: 0;
    height: 35px;
    line-height: 35px;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}
.swiper-paging a{
  display: inline-block;
  width: 60px;
  height: 60px;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}
.swiper-paging .left_arrow {
  position: absolute;
  left: 40%;
  top: 0;
  z-index: 99;
}
.swiper-paging .right_arrow {
  position: absolute;
  right: 40%;
  top: 0;
}
.swiper-paging .paging_number {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
}
.swiper-paging .prev{
  display: inline-block;
  width: 15px;
  height: 15px;
  border-top: solid 2px #6a6969;
  border-left: solid 2px #6a6969;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.swiper-paging .next{
  position: relative;
  right: 17px;
  display: inline-block;
  width: 15px;
  height: 15px;
  margin: 1px;
  border-top: solid 2px #6a6969;
  border-right: solid 2px #6a6969;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.swiper-paging span{
  margin: 0 35px;
  font-size: 15px;
  font-weight: bold;
}
.swiper-paging .prev,
.swiper-paging .next,
.swiper-paging span{
  vertical-align: middle;
}

/* ------------------------------------------------------------------------
  tooltip
--------------------------------------------------------------------------*/
.tooltip_container{
  background-color: #808080;
  border-radius: 10px;
  padding: 10px;
  margin: 5px auto;
  width: fit-content;
  text-align: center;
  transition: all 0.3s;
}
.tooltip{
  color: #ffffff;
  font-size: 14px;
}

/* ------------------------------------------------------------------------

  basic & nomura

--------------------------------------------------------------------------*/
/*------------------------------------------------------------------
      appstrage - sp
-------------------------------------------------------------------*/
.app_sp {
  background: #F6F6F6;
}
.app_sp ul {
  list-style-type: none;
}
.app_sp ul.folder_list {
  margin: 2em;
}

.list_description {
  color: #999797;
  line-height: 1.2;
  margin-top: 5px;
}
.app_sp ul.file_list li:last-child , ul.file_folder_list li:last-child {
  border-bottom: none;
}
.app_sp ul.file_list li a {
  display: flex;
  justify-content: space-between;
  padding: 1.5em 0.5em 1.5em 3.5em;
}
.app_sp ul.folder_list li , .dir_angle_circle{
  background: url(/public/common/image/icon/icon_folder_navy.png);
  background-repeat: no-repeat;
  background-position: 30px center;
  background-size: 30px;
  background-color: #fff;
  border-radius: 1em;
  margin-bottom: 2em;
}
.app_sp ul.folder_list li.icon_torisetu{
  background-image: url(/public/common/image/icon/torisetu.png) !important;
  background-repeat: no-repeat;
  background-position: 20px center;
}
.app_sp ul.folder_list li.icon_shinsei{
  background-image: url(/public/common/image/icon/shinsei.png) !important;
  background-repeat: no-repeat;
  background-position: 20px center;
}
.app_sp ul.folder_list li.icon_bousai{
  background-image: url(/public/common/image/icon/bosai.png) !important;
  background-repeat: no-repeat;
  background-position: 20px center;
}
.app_sp ul.folder_list li.icon_info{
  background-image: url(/public/common/image/icon/info.png) !important;
  background-repeat: no-repeat;
  background-position: 20px center;
}

.app_sp ul.folder_list li a {
  display: block;
  padding: 2em 2em 2em 6em;
}
.app_sp ul.folder_list li h3 {
  line-height: 2em;
}
.app_sp ul.folder_list li p {
  color: #8C8C8C;
  line-height: 1.2em;
}

.app_sp ul.file_list {
  margin: 0.5em 0;
}
ul.file_folder_list a h3 {
  display: grid;
  line-height: 1.2;
  margin: 5px auto;
}
.app_sp ul.file_list li a span , .capacity_text {
  color: #999797;
  margin: 0px 10px 0px 0px;
}
.as_text {
  padding-right: 20px;
  font-size: 1.5rem;
}

/* filelist.html start */
.search_area {
  position: relative;
  background-color: #f0f0f0;
  height: 60px;
  font-size: 1.5rem;
}
.search_area h2 {
  display: flex;
  margin: 0 auto;
  align-items: center;
  width: 78%;
  height: 100%;
  text-align: center;
  justify-content: center;
  word-break: break-all;
}

.left_arrow_style {
  /* position: absolute;
  left: 0; */
  display: inline-block;
  margin-left: 12px;
  width: 12px;
  height: 22px;
}
.left_justified {
  position: absolute;
  left: 0;
  width: 55px;
  height: 100%;
  display: flex;
  align-items: center;
}
.search_icon {
  position: absolute;
  height: 28px !important;
  right: 0;
  margin: 25px 10px 5px;
}
.file_item_a {
  display: inline-block;
  padding: 1.5em 1em 1.5em 4.5em;
  width: 100%;
  word-break: break-all;
}
.star_icon {
  display: inline-block;
  width: 23px;
  height: 23px
}
.checklist input {
  display: none;
}
.inner_wrap.favorite_wrap input.favorite_mark[type="checkbox"] {
  display: none;
}
.inner_wrap.favorite_wrap input[type="checkbox"] + label::before{
  background: none;
  border: none;
  border-radius: 0;
}
.inner_wrap.favorite_wrap .check_off,
.inner_wrap.favorite_wrap .check_on{
    display: block;
    background-size: 24px;
    background-position: left center;
    background-repeat: no-repeat;
    padding: 4px 0 0 28px;
    height: 20px;
    width: auto;
    position: static;
    cursor: pointer;
}
.inner_wrap.favorite_wrap .check_off{
    background-image: url(/public/common/image/icon/icon_star.png);
}
.inner_wrap.favorite_wrap .check_on{
    background-image: url(/public/common/image/icon/icon_star_check.png);
}
.inner_wrap.favorite_wrap input[type="checkbox"] + label::after {
    background: none;
}
.star_inner {
  fill:#fff;
}
.star_frame {
  fill: none;
  stroke:#232424;
  stroke-miterlimit:10;
  stroke-width:3px;
}
.star_frame_normal {
  fill: none;
  stroke: #616161;
  stroke-miterlimit: 10;
  stroke-width: 3px;
}
.checkmark {
  padding: 0 !important;
  margin: 10px;
}
.inner_wrap input:checked + label .star_frame {
  fill: #232424;
  stroke: #232424;
}
.inner_wrap input:checked + label .star_frame_normal {
  fill: #616161;
  stroke: #616161;
}
.checkmark::before , .checkmark::after {
  display: none !important;
}
.inner_wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* filelist.html indicator */
.display_none {
	display: none;
}
.indicator_wrap {
  position: fixed;
  z-index: 99;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
.indicator_wrap_search {
  /* display: none; */
  position: fixed;
  z-index: 99;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 0;
  height: 100%;
  width: 100%;
}
.indicator_bg {
  background-color: rgba(255,255,255,0.5);
  align-items: center;
  height: 100%;
}
.indicator_icon_wrap {
  display: flex;
  align-items: center;
  height: 100%;
}
.indicator_icon_wrap img {
  display: block;
  margin: 0 auto;
  max-width: 60px;
  max-height: 60px;
}

/* filelist.html indicator */

/* popup start */
div.filelist_body.fixed {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
}
.popup_wrap {
  display: none;
  position: fixed;
  z-index: 99;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  overflow: scroll;
}
.popup_wrap input[type="radio"] {
  display: none;
}
.popup_bg {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.popup_body {
  width: 90%;
  height: 200px;
  background: #fff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  margin: auto;
  margin-top: 50px;
}
.popup_title {
  display: inline-block;
  margin-top: 15px;
  margin-left: 15px;
  margin-bottom: 10px;
}
.popup_close {
  float: right;
  display: inline-block;
  width: 23px;
  height: 20px;
  margin: 12px;
  margin-left: 0;
  border: 1px solid #999;
  color: #999;
  border-radius: 3px;
  line-height: 20px;
  text-align: center;
  font-size: 1.3rem;
}
.popup_close::after {
  clear: both;
}
.select_button_wrap {
  width: 85%;
  margin: 15px auto;
}
.select_button_wrap input {
  display: none;
}
.form_area input {
  display: block;
  margin: 10px auto;
}
.checkbox_style::before {
  width: 12px !important;
  height: 12px !important;
}
.checkbox_style::after {
  width: 8px !important;
  height: 8px !important;
  left: 8px !important;
  top: -1px !important;
}
.checkbox_text_style {
  vertical-align: middle;
}
.search_textbox {
  width: 85%;
  height: 30px;
  border: 1px solid #808080 !important;
  font-size: 16px !important;
}

.caution_description {
  text-align: center;
  line-height: 1.3;
}
/* popup end */
.cls-1 {
  width: 20px;
  height: 20px;
}
html , .filelist_body {
  background-color: #F6F6F6;
}


/* storageview start */
.swiper-button-prev , .swiper-button-next {
  background-image: none !important;
  width: 60px !important;
  height: 90px !important;
  /* border-left: 8px solid #ffffff;
  border-bottom: 8px solid #ffffff; */
  box-sizing: border-box;
  position: relative;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}
.swiper-button-prev::after , .swiper-button-next::after {
  content: '';
  display: block;
  width: 26px !important;
  height: 26px !important;
  /* border-left: 4px solid #9b9b9b;
  border-bottom: 4px solid #9b9b9b; */
  background-image: none !important;
  box-sizing: border-box;
  position: absolute;
  left: -6px;
  top: 4px;
}
.view_inner {
  width: 100%;
  padding: 20px;
  background-color: #ffffff;
  margin: auto;
  box-sizing: border-box;
}

.storageview_content {
  display: flex;
  position: relative;
  height: calc(100vh - 60px);
}

.modal-viewer .viewer .viewer_main span {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 600px;
  border: 2px solid #cccccc;
  border-top-color: rgb(204, 204, 204);
  border-top-style: solid;
  border-top-width: 2px;
  border-right-color: rgb(204, 204, 204);
  border-right-style: solid;
  border-right-width: 2px;
  border-bottom-color: rgb(204, 204, 204);
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-left-color: rgb(204, 204, 204);
  border-left-style: solid;
  border-left-width: 2px;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;
  background-color: #f1f1f1;
  color: #808080;
}
.swiper-slide {
  width: 90%;
  margin: 0 auto;
}
.swiper_image_size {
  display: block;
  max-width: 100%;
  max-height: Calc(100vh - 95px);
  margin: 0px auto;
}
.swiper-container {
  height: 100%;
}
.swiper-slide iframe[src$=".pdf"]{
  width:100%;
  max-height:auto;
  height: 100%;
}
.strageview_header {
  position: relative;
  display: flex;
  justify-content: space-between;
  background-color: #f0f0f0;
  height: 60px;
  font-size: 1.5rem;
}
.strageview_header a {
  display: flex;
  align-items: center;
  position: absolute;
  left: 0;
  width: 55px;
  height: 100%;
}
.title {
  display: flex;
  align-items: center;
  padding: 15px;
  font-weight: bold;
  line-height: 1.3;
  text-align: center;
}
.title_text {
  display: flex;
  align-items: center;
  /* padding: 15px; */
  padding-top: 15px;
  padding-bottom: 15px;
  font-weight: bold;
  line-height: 1.3;
  text-align: center;
}
.strageview_header > .title {
  width: 80%;
  display: flex;
  margin: 0 auto;
  align-items: center;
  padding: 0;
  justify-content: center;
}
.strageview_header > .title_area {
  width: 80%;
  display: flex;
  margin: 0 auto;
  align-items: center;
  padding: 0;
  justify-content: center;
}
.strageview_header > .title_area > .download {
  border: none;
  border-radius: 0px;
  box-shadow: none;
  background-color: transparent;
}
.download_icon_style {
  position: absolute;
  display: inline-block;
  top: 0;
  right: 0;
  margin-top: 18px;
  margin-left: 12px;
  margin-right: 12px;
  width: 25px;
  height: 25px;
}

.left_arrow {
  position: absolute;
  top: 50%;
  bottom: 0;
  left: 10px;
}
.right_arrow {
  position: absolute;
  top: 50%;
  bottom: 0;
  right: 10px;
}
.view_body {
  width: 95%;
  margin: 0px auto;
}
/* user_error.html */
.body_style {
  width: 100%;
  height: 100vh;
  background-color: transparent !important;
}

.user_error {
  display: block;
  background-color: #fff;
  border-radius: 1em;
  padding-top: 20vh;
  height: 80vh;
  text-align: center;
}

.user_error .error_message {
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 80%;
}
/* / user_error.html */
@media screen and (max-width:480px) {
  .swiper-slide {
    width: 100%;
    /*height: 60vh !important;*/
  }
}
@media screen and (min-width:768px) {
  .back_btn {
    display: none;
  }
  .swiper-paging .left_arrow {
    position: absolute;
    left: 30%;
    top: 0;
  }
  .swiper-paging .right_arrow {
    position: absolute;
    right: 30%;
    top: 0;
  }
}
@media screen and (max-width:1024px) {
  .swiper-paging .left_arrow {
    position: absolute;
    left: 10%;
    top: 0;
  }
  .swiper-paging .right_arrow {
    position: absolute;
    right: 10%;
    top: 0;
  }
}

/* storageview end */


/* ------------------------------------------------------------------------

  basic

--------------------------------------------------------------------------*/
/*------------------------------------------------------------------
      appstrage - sp
-------------------------------------------------------------------*/
.basic .app_sp ul.file_list li {
  background-image: url(/public/common/image/icon/icon_file_navy.png);
  background-repeat: no-repeat;
  background-position: 15px center;
  border-bottom: solid 1px #EDEBEB;
  background-color: #fff;
}
.basic ul.file_folder_list li {
  display: flex;
  justify-content: space-between;
  background-image: url(/public/common/image/icon/icon_file_navy.png);
  background-repeat: no-repeat;
  background-position: 15px center;
  border-bottom: solid 1px #EDEBEB;
  background-color: #fff;
  background-size: auto 25px;
}
.basic .normal_list_img {
  display: flex !important;
  justify-content: space-between !important;
  background-image: url(/public/common/image/icon/icon_file_gray.png) !important;
  background-repeat: no-repeat !important;
  background-position: 15px center !important;
  border-bottom: solid 1px #EDEBEB;
  background-color: #fff;
}
.basic .normal_folder_img {
  display: flex !important;
  justify-content: space-between !important;
  background-image: url(/public/common/image/icon/icon_folder_gray.png) !important;
  background-size: auto 18px!important;
  background-repeat: no-repeat !important;
  background-position: 15px center !important;
  border-bottom: solid 1px #EDEBEB;
  background-color: #fff;
}
.basic .st0 {
  fill:none;
  stroke:#616161;
  stroke-miterlimit:10;
}
.basic .st1 {
  stroke:#616161;
  stroke-miterlimit:10;
  fill:#616161;
}
.basic .st2 {
  fill:none;
  stroke:#616161;
  stroke-miterlimit:10;
}
.search_icon_btn{
  position: absolute;
  top:0;
  right: 0;
  background-image: url(/public/common/image/icon/icon_app_search.png);
  background-repeat: no-repeat;
  background-size: 25px 25px;
  background-position: 5px 0;
  margin-right: 5px;
  width: 35px;
  height: 35px !important;
  margin-top: 18px;
}

/* popup start */
.basic .search_button {
  width: 85%;
  height: 35px;
  border-radius: 35px;
  background-color: #616161;
  color: #fff;
  font-size: 1.2rem;
}
/* popup end */

.basic .dir_angle_circle {
  background-image: url(/public/common/image/icon/icon_folder_navy.png);
  background-repeat: no-repeat;
  background-position: 20px center;
  background-color: #fff;
  border-radius: 1em;
  margin: 10px;
}
.basic .count__text {
  text-align: right;
  margin-top: -10px;
}

/* viewer(pdf) */
canvas {
 width: auto !important;
 height: 100% !important;
}

.viewerform_pdf {
  height: 75vh;
  width: 100%;
  display: block;
  margin: 10px auto;
}

@media screen and (max-width: 1024px) {

  canvas {
   width: auto !important;
   height: auto !important;
  }

  .swiper-slide {
    width: 100%;
    min-height: 60vh !important;
  }

}

/* ------------------------------------------------------------------------

  nomura

--------------------------------------------------------------------------*/
/*------------------------------------------------------------------
      appstrage - sp
-------------------------------------------------------------------*/
.nomura .developer_file_icon {
  display: flex;
  justify-content: space-between;
  background-image: url(/public/common/image/icon/icon_file_navy.png);
  background-repeat: no-repeat;
  background-position: 15px center;
  border-bottom: solid 1px #EDEBEB;
  background-color: #fff;
  background-size: auto 25px;
}

.nomura .developer_list {
  display: flex;
  justify-content: space-between;
  background-repeat: no-repeat;
  border-bottom: solid 1px #EDEBEB;
  background-color: #fff;
}

.nomura .developer_list.icon_file,
.nomura .developer_list_tree.icon_file{
  background-image: url(/public/common/image/icon/icon_file_navy.png);
  background-size: auto 25px;
  background-position: 15px center;
}
.nomura .developer_list.icon_folder,
.nomura .developer_list_tree.icon_folder{
  background-image: url(/public/common/image/icon/icon_folder_navy.png);
  background-size: 30px auto;
  background-position: 15px center;
}
.nomura .developer_list.icon_bosai,
.nomura .developer_list_tree.icon_bosai{
  background-image: url(/public/common/image/icon/bosai.png);
  background-size: 30px auto;
  background-position: 15px center;
}
.nomura .developer_list.icon_info,
.nomura .developer_list_tree.icon_info{
  background-image: url(/public/common/image/icon/info.png);
  background-size: auto 25px;
  background-position: 15px center;
}
.nomura .developer_list.icon_shinsei,
.nomura .developer_list_tree.icon_shinsei{
  background-image: url(/public/common/image/icon/shinsei.png);
  background-size: auto 25px;
  background-position: 22px center;
}
.developer_list.icon_torisetu,
.developer_list_tree.icon_torisetu{
  background-image: url(/public/common/image/icon/torisetu.png);
  background-size: auto 25px;
  background-position: 15px center;
}


.nomura .search_area.developer_header{
 display: none;
}

.nomura .search_icon_btn{
  background-image: url(/public/common/image/icon/icon_search.png);
  background-repeat: no-repeat;
  background-size: 25px 25px;
  width: 35px;
  height: 35px;
  margin-top: 18px;
  margin-right: 5px;
}
/* popup start */
.nomura .search_button {
  width: 85%;
  height: 35px;
  border-radius: 35px;
  background-color: #222a5d;
  color: #fff;
  font-size: 1.2rem;
}
/* popup end */

.nomura .developer_folderlist {
  display: flex;
  justify-content: space-between;
  background-image: url(/public/common/image/icon/icon_folder_navy.png);
  background-repeat: no-repeat;
  background-position: 15px center;
  border-bottom: solid 1px #EDEBEB;
  background-color: #fff;
  background-size: auto 18px;
}

.nomura .developer_list_tree {
  display: flex;
  justify-content: space-between;
  background-repeat: no-repeat;
  border-bottom: solid 1px #EDEBEB;
  background-color: #fff;
}

.nomura .dir_angle_circle {
  background-position: 20px center;
  background-color: #fff;
  border-radius: 1em;
  margin: 10px;
}
.nomura .developer_invisible {
  display: none;
  /* お気に入りマーク非表示 */
}
.nomura .star_inner_navy {
  fill:#fff;
}
.nomura .star_frame_normal_navy {
  fill: none;
  stroke: #222a5d;
  stroke-miterlimit: 10;
  stroke-width: 3px;
}
.nomura .inner_wrap input:checked + label .nomura .star_frame_normal_navy {
  fill: #222a5d;
  stroke: #222a5d;
}
.nomura .st0 {
  fill:none;
  stroke:#222a5d;
  stroke-miterlimit:10;
}
.nomura .st1 {
  stroke:#222a5d;
  stroke-miterlimit:10;
  fill:#222a5d;
}
.nomura .st2 {
  fill:none;
  stroke:#222a5d;
  stroke-miterlimit:10;
}
.nomura .favorite_wrap {
  display: none;
}


div.actionfield_action select {
    max-width: 100%;
}

/* IE　游ゴシック対応 */

@media all and (-ms-high-contrast: none){
  .intro .button{
    padding-top: 20px;
  }
  .select_all label {
    padding-top: 4px;
  }
  .select_menu .select_list > .button {
    line-height: 18px;
  }
  .drag p {
    padding-top: 7px;
  }
  .button {
    overflow: visible;
  }
  .button.button-delete {
    display: block;
    overflow: visible;
  }
  .button.button-delete span {
    display: block;
    height: 13px;
    padding-top: 6px;
    padding-bottom: 4px;
  }
  .button.button-delete span::before {
    overflow: visible;
    background-position: 0;
  }
}

/* 非公開フォルダ */
.bg-gray{
  background-color: #cccccc;
}
.table table tbody tr.bg-gray td+td{
  border-left-color: #ddd;
}
.table table tbody tr+tr.bg-gray{
  border-top-color: #ddd;
}
.bg-gray .icon-folder:before {
  background-image: url(/public/common/image/icon/icon_folder_hidden.png);
}
.bg-gray .button, .main .bg-gray .button {
  background-color: #fff;
}
.table table tbody tr.bg-gray:hover {
  background-color: #c6c6c6;
}
